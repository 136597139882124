import type { Feature } from 'ol';
import type { Point, SimpleGeometry } from 'ol/geom';
import type { Select } from 'ol/interaction';
import type VectorLayer from 'ol/layer/Vector';
import type VectorSource from 'ol/source/Vector';

import { useContext, createContext } from 'react';

interface LiveRouteContextProps {
  readonly forkliftsSource: VectorSource<Feature<Point>>;
  readonly forkliftsLayer: VectorLayer<VectorSource<Feature<Point>>>;
  readonly excludedAreasSource: VectorSource<Feature<SimpleGeometry>>;
  readonly excludedAreasLayer: VectorLayer<VectorSource<Feature<SimpleGeometry>>>;
  readonly selectInteraction: Select;
}

export const LiveRouteContext = createContext<LiveRouteContextProps | undefined>(undefined);
LiveRouteContext.displayName = 'LiveRouteContext';

export const LiveRouteProvider = LiveRouteContext.Provider;

export const useLiveRouteCtx = () => {
  const ctx = useContext(LiveRouteContext);

  if (!ctx) {
    throw new Error('Component beyond LiveRouteContext.');
  }

  return ctx;
};
